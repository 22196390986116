export default {
  name: 'All accounts',
  subtitle: 'To search for accounts type in some values into filter inputs below',
  fields: {
    number: 'Number',
    type: 'Type',
    alias: 'Alias',
    currency: 'Currency',
    owner: 'Owner',
    createdAt: 'Created at',
    status: 'Status',
    withDeleted: 'With deleted',
    withError: 'With error',
    noResultsFound: 'No results found',
    searchInProgress: 'Search is in progress...',
  },
  tabs: {
    details: 'Details',
    transactions: 'Transactions',
    events: 'Events',
  },
  subheader: 'Id:  %{id}. Created:  %{date}  %{time}',
  show: {
    details: {
      status: 'Status',
      product: 'Product',
      type: 'Type',
      balancesAndCounters: 'Balances and counters',
      systemInfo: 'System information',
      aliasAndDescription: 'Alias and description',
      productTemplateName: 'Product template name',
      productTemplateCode: 'Product template code',
      productTemplateCategory: 'Product template category',
      bankAccount: 'Bank account',
      currency: 'Currency',
      currentDay: 'Current day',
      alias: 'Alias',
      description: 'Description',
      owner: 'Owner',
      closedOn: 'Closed on',
    },
  },
  actions: {
    attention: 'Attention!',
    blockingAlert: 'Please confirm the blocking of the %{accountType} and indicate the reason.',
    unblockingAlert: 'Please confirm the unblocking of the %{accountType} and indicate the reason.',
    closingAlert: 'Please confirm the closing of the %{accountType} and indicate the reason.',
    reason: 'Reason',
    accounts: 'account',
    externalSources: 'external source',
  },
};
