import React, { FC, Fragment, useState } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { time } from '~/utils';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import {
  H2,
  TextField,
  AmountField,
  StatusField,
  AccordionSummary,
  AccordionDetails,
  RunTransferAction,
  AccountsActions,
  LinkToAccount,
  AffiliatedWith,
  ChipField,
  AccountOwner,
  AccountWithErrorWarning,
} from '~/components';
import { ShowController } from '~/components/ra';
import { Account } from '~/types/account';
import LimitsAndCounters from './LimitsAndCounters';
import NoPermissions from '../NoPermissions';
import AccountCreate from './AccountCreate';
import { useGetAllAccounts } from '~/hooks/accounts';
import EditSourceName from '~/components/EditSourceName';
import BalancesTable from './BalancesTable';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

// Style AccordionSummary to prevent focused grey color when clicking inside of dialog.
const AccordionSummaryStyled = withStyles({
  root: {
    '&.Mui-focused': {
      backgroundColor: 'inherit',
    },
  },
})((props: any) => {
  return <AccordionSummary {...props} />;
});

export const l = (key: string): string => `components.ra.AccountList.${key}`;

interface Props {
  resource: 'businesses' | 'individuals' | 'partners';
  profileId: string;
  basePath: string;
  permissions: string[];
  solutionId?: string;
}

const Accounts = (props: Props) => {
  const { resource, profileId, basePath, permissions, solutionId } = props;
  const { data, isLoading: loading, refetch } = useGetAllAccounts({ resource, profileId });
  const accounts = data?.filter(
    (item: Account | undefined) => item?.productTemplate?.category !== 'CNL_ACCOUNT'
  );
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  if (!permissions) return null;
  if (resource === 'businesses') {
    if (!permissions.includes('business.account.view')) return <NoPermissions />;
  }
  if (resource === 'individuals') {
    if (!permissions.includes('individual.account.view')) return <NoPermissions />;
  }
  if (resource === 'partners') {
    if (!permissions.includes('partner.account.view')) return <NoPermissions />;
  }

  let hasCreatePermission = false;
  if (resource === 'businesses') {
    hasCreatePermission = permissions.includes('business.account.create');
  } else if (resource === 'individuals') {
    hasCreatePermission = permissions.includes('individual.account.create');
  } else if (resource === 'partners') {
    hasCreatePermission = false; // permissions.includes('partner.account.create');
  }

  const hasRunTransferPermission =
    permissions.includes('settlement.runTransfer') ||
    permissions.includes('accountTransfer.runTransfer');

  function sortByOwnerAndStatus(a: Account, b: Account) {
    if (a.profileId === profileId && b.profileId !== profileId) {
      return -1;
    } else if (a.profileId !== profileId && b.profileId === profileId) {
      return 1;
    } else {
      const statusOrder = { ACTIVE: 0, BLOCKED: 1, CLOSED: 2 };
      return statusOrder[a.status] - statusOrder[b.status];
    }
  }

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t(`accounts`)}
        </H2>
        <div id='section-actions'>
          {hasCreatePermission && (
            <AccountCreate
              refetch={refetch}
              resource={resource}
              solutionId={solutionId as string}
              profileId={profileId}
            />
          )}
          {hasRunTransferPermission && (
            <RunTransferAction
              profileId={profileId}
              resource={resource}
              onDone={() => {
                refetch();
              }}
              permissions={permissions}
            />
          )}
        </div>
      </header>
      {loading && <Skeleton width='100%' height='32px' />}
      {!loading && !accounts?.length && <div>{t('noAccounts')}</div>}
      {!loading &&
        accounts &&
        accounts.sort(sortByOwnerAndStatus).map((account: Account, index: number) => {
          const {
            alias,
            balances,
            number,
            status,
            created,
            error,
            id: accountId,
          } = account as Account;
          const key = (account as Account).id as string;
          const isExpanded = Boolean(expanded[key]);
          const currentAvailable = balances?.find(
            (item) => item.code === 'CURRENT_AVAILABLE' || item.code === 'CURRENT_FLOAT'
          )?.amount;
          const heading = (
            <Grid container>
              {alias ? (
                alias
              ) : (
                <ReferenceField
                  source='productId'
                  reference='products'
                  link={false}
                  addLabel={false}
                  record={account}
                  basePath={basePath}
                >
                  <ShowController>
                    {({ showRecord }) => {
                      return <div>{showRecord?.name || 'Unnamed account'}</div>;
                    }}
                  </ShowController>
                </ReferenceField>
              )}
              {account.profileId !== profileId && (
                <ChipField style={{ margin: '0 10px' }} label={t('shared')} />
              )}
            </Grid>
          );

          return (
            <Grid key={index} item xs={12} className={classes.account}>
              <Accordion expanded={isExpanded} onChange={handleChange(key, isExpanded)}>
                <AccordionSummaryStyled
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={heading}
                  actionNode={
                    <Grid container justify='flex-end' spacing={1}>
                      <Grid item className='account-actions'>
                        <LinkToAccount id={account.id} />
                        <EditSourceName
                          source='accounts'
                          resource={resource}
                          record={account}
                          refetch={refetch}
                        />
                        {resource !== 'partners' && (
                          <AccountsActions
                            status={status}
                            resource={resource}
                            record={account}
                            refetch={refetch}
                            type='accounts'
                          />
                        )}
                      </Grid>
                      <Grid item alignItems='center' className={classes.balanceContainer}>
                        {currentAvailable && <AmountField amount={currentAvailable} />}
                      </Grid>
                    </Grid>
                  }
                  content={
                    <Grid container spacing={3}>
                      {error && (
                        <Grid item xs={12}>
                          <AccountWithErrorWarning
                            accountId={accountId}
                            error={error}
                            refetch={refetch}
                          />
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <TextField label={t('number')} loading={loading} copyable>
                          {number}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('created')} loading={loading}>
                          {time(created).format('L')}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('status')} loading={loading}>
                          <StatusField status={status} />
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                />
                <AccordionDetails>
                  <AccountData
                    data={account}
                    loading={loading}
                    resource={resource}
                    profileId={profileId}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
    </div>
  );
};

interface AccountData {
  data: Account | object;
  loading: boolean;
  resource: string;
  profileId: string;
}

const AccountData: FC<AccountData> = (props) => {
  const { data, loading, resource, profileId } = props;
  const {
    balances,
    id: accountId,
    references,
    profileId: accountProfileId,
    profileType: accountProfileType,
    closed,
  } = data as Account;
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>
        {closed && (
          <Grid item xs={4}>
            <TextField label={t('closedOn')} copyable>
              {time(closed).format('L')}
            </TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <BalancesTable balances={balances} loading={loading} resource={resource} />
        </Grid>
        {accountId && (
          <Grid item xs={12}>
            <LimitsAndCounters id={accountId} />
          </Grid>
        )}
        {data && accountProfileId !== profileId && (
          <Grid item xs={12}>
            <AccountOwner
              profileId={accountProfileId}
              profileType={accountProfileType}
              noPaddingLeft
              topSpacing={2}
              withIcon
            />{' '}
          </Grid>
        )}
        <Grid item xs={12}>
          <AffiliatedWith references={references} type='SHARED_WITH' withIcon noPaddingLeft />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(2),
  },
  account: {
    '& .account-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover .account-actions': {
      opacity: 1,
    },
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  balanceContainer: {
    display: 'flex',
    fontSize: '16px',
  },
}));

export default Accounts;
