import React, { FC, Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import { time } from '~/utils';

import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import theme from '~/theme';
import { Business, Transaction, Amount, Column, Partner, Individual, Employee } from '~/types';
import { Target } from '~/components/TransactionsHistory';
import {
  H2,
  H3,
  AmountField,
  StatusField,
  TextField,
  Drawer,
  FxRateField,
  ApiLocalizedTextField,
} from '~/components';
import { MerchantCategoryCodeField, AddressField, ShowController } from '~/components/ra';
import { SenderIcon, RecipientIcon, ReceiptIcon as PartnersIcon } from '~/img';
import { OperationTypeField } from '~/components/TransactionsHistory/cellComponents';
import { isEmpty } from 'lodash';
import ProfileReference from './ProfileReference';
import moment from 'moment';
import { useGetServiceQuery } from '~/hooks/paymentServices';
import ExternalSourceReference from './ExternalSourceReference';
import { PocketIcon } from '~/img';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    width: 395,
    '& h3': {
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContend: {
    paddingLeft: theme.spacing(4),
  },
  typeCell: {
    minWidth: 90,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  idTextField: {
    overflowWrap: 'break-word',
  },
  h3WithGoToAction: {
    '& button': {
      padding: 5,
      marginTop: -4,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
}));

const label = (key: string): string => `components.TransactionView.${key}`;

const AmoutRow = (props: { category: string; amount?: Amount; item?: any }) => {
  const { category, amount, item } = props;

  return (
    <TableRow>
      <TableCell>{category}</TableCell>
      <TableCell>
        {amount && <AmountField amount={amount} />}
        {item}
      </TableCell>
    </TableRow>
  );
};

interface TransactionServiceViewProps {
  transaction: Transaction;
  target: Target;
}

const TransactionServiceView: FC<TransactionServiceViewProps> = (props) => {
  const { transaction, target } = props;
  const service = transaction.details.service!;
  const parameters = service.parameters || {};

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const { data: serviceSchema } = useGetServiceQuery(service.id!, true);

  const parameterFields = Object.keys(parameters).map((key) => {
    const value = typeof parameters[key] === 'object' ? parameters[key].value : parameters[key];
    const name = serviceSchema?.parameters[key]?.name || key;
    return (
      <Grid key={key} item xs={12}>
        <TextField label={name}>{value}</TextField>
      </Grid>
    );
  });

  return (
    <Fragment>
      <H3 icon={<PartnersIcon />}>{t('service')}</H3>
      <Grid item container spacing={2} className={classes.innerContend}>
        <Grid item xs={12}>
          <TextField label={t('serviceName')} textWrap>
            {service.name}
          </TextField>
        </Grid>
        {target !== 'partners' && (
          <Grid item xs={12}>
            <TextField label={t('partnerId')} textWrap>
              {service.partnerId}
            </TextField>
          </Grid>
        )}
        {parameterFields}
      </Grid>
    </Fragment>
  );
};

interface Props {
  record: Individual | Business | Partner;
  target: Target;
  transaction: Transaction | null;
  onClose: () => void;
}

const TransactionView: FC<Props> = ({ record, target, transaction, onClose }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const additionalData = getAdditionlData(transaction);

  const showAmount =
    transaction?.baseAmount.currency !== transaction?.amount.currency ||
    Math.abs(transaction?.baseAmount.value || 0) !== Math.abs(transaction?.amount.value || 0);

  const showInstructedAmount =
    transaction?.operationType === 'CASH_DEPOSIT' ||
    transaction?.operationType === 'CASH_WITHDRAWAL';

  const iAmSender = (transaction?.amount.value || 0) < 0;

  const senderRate = transaction?.details.currencyConversion?.sender?.rate;
  const recipientRate = transaction?.details.currencyConversion?.recipient?.rate;
  const exchangeRate = iAmSender ? senderRate : recipientRate;

  const party = iAmSender ? transaction?.sender : transaction?.recipient;
  const counterparty = iAmSender ? transaction?.recipient : transaction?.sender;

  const showParty =
    transaction?.originator?.profileId === record.id &&
    transaction.sender?.profileId !== record.id &&
    transaction.recipient?.profileId !== record.id;

  const cashAcquiringEntries =
    transaction?.entries?.filter((e) => e.category === 'CASH_ACQUIRING_FEE') || [];

  const cashAcquiringFeesRows = (
    <>
      {cashAcquiringEntries.map((item, index) => {
        const { category, amount, description } = item;
        return (
          <AmoutRow
            key={index}
            category={description === category ? t(category) : description}
            amount={amount}
          />
        );
      })}
    </>
  );

  return (
    <Drawer
      heading={
        <Box>
          <H2 style={{ margin: 0 }}>{transaction?.description || ''}</H2>
          <Typography variant='subtitle1' style={{ color: theme.palette.text.secondary }}>
            ID {transaction?.id}
          </Typography>
        </Box>
      }
      open={!!transaction}
      onClose={onClose}
    >
      {transaction && (
        <Fragment>
          <H3 icon={<RecipientIcon />} topSpacing={0}>
            {t('instruction')}
          </H3>

          <Grid
            item
            container
            xs={12}
            spacing={2}
            className={`${classes.bottomSpacing} ${classes.innerContend}`}
          >
            <Grid item xs={12}>
              <TextField label={t('operationType')}>
                <OperationTypeField rowData={transaction} column={{} as Column}>
                  {transaction.operationType}
                </OperationTypeField>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <ApiLocalizedTextField
                keyPrefix='operationSubtype'
                secondKey={transaction.operationSubtype}
                label={t('operationSubtype')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('createdAt')}>
                {time(transaction.createdAt).format('ll, LTS')}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <AmountField label={t('baseAmount')} amount={transaction.baseAmount} />
            </Grid>
            {transaction.beneficiaryAmount && (
              <Grid item xs={12}>
                <AmountField
                  label={t('beneficiaryAmount')}
                  amount={transaction.beneficiaryAmount}
                />
              </Grid>
            )}
            {(showAmount ||
              showInstructedAmount ||
              cashAcquiringEntries.length > 0 ||
              exchangeRate) && (
              <Grid item xs className={`${classes.bottomSpacing} ${classes.innerContend}`}>
                <Table>
                  <TableBody>
                    {showInstructedAmount && (
                      <AmoutRow
                        category={t('instructedAmount')}
                        amount={transaction?.instructedAmount}
                      />
                    )}
                    {cashAcquiringFeesRows}
                    {exchangeRate && (
                      <AmoutRow
                        category={t('exchangeRate')}
                        item={
                          <FxRateField
                            baseCurrency={
                              (iAmSender
                                ? transaction?.details.currencyConversion?.sender?.baseCurrency
                                : transaction?.details.currencyConversion?.recipient
                                    ?.baseCurrency) ||
                              (
                                (iAmSender
                                  ? transaction.beneficiaryAmount
                                  : transaction.remitterAmount) ||
                                transaction.baseAmount ||
                                transaction.amount
                              ).currency
                            }
                            quoteCurrency={
                              (iAmSender
                                ? transaction?.details.currencyConversion?.sender?.quoteCurrency
                                : transaction?.details.currencyConversion?.recipient
                                    ?.quoteCurrency) || transaction.totalAmount.currency
                            }
                            rate={exchangeRate}
                          />
                        }
                      />
                    )}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>

          {showParty && party && (
            <Grid
              item
              container
              spacing={2}
              className={`${classes.bottomSpacing} ${classes.innerContend}`}
            >
              <Grid item xs={12}>
                <ProfileReference
                  label={t('party')}
                  party={party}
                  ownerId={record.id}
                  additionalName={transaction.details.recipientName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label={`${t('partyAccount')} ${party.accountId}`}>
                  {party.accountNumber}
                </TextField>
              </Grid>
            </Grid>
          )}

          {counterparty && (
            <Grid
              item
              container
              spacing={2}
              className={`${classes.bottomSpacing} ${classes.innerContend}`}
            >
              <Grid item xs={12}>
                <ProfileReference
                  label={t('counterparty')}
                  party={counterparty}
                  ownerId={record.id}
                  additionalName={transaction.details.recipientName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label={`${t('counterpartyAccount')} ${counterparty.accountId}`}>
                  {counterparty.accountNumber}
                </TextField>
              </Grid>
            </Grid>
          )}

          <H3 icon={<SenderIcon />}>{t('transactionResult')}</H3>

          <Grid
            item
            container
            xs={12}
            spacing={2}
            className={`${classes.bottomSpacing} ${classes.innerContend}`}
          >
            <Grid item xs={6}>
              <TextField label={t('status')}>
                <StatusField status={transaction.status} />
              </TextField>
            </Grid>
            {transaction.headTransactionId && (
              <Grid item xs={6}>
                <TextField
                  label={t('headTransaction')}
                  showLink={`/headTransactions/${transaction.headTransactionId}/show`}
                >
                  <>{`${transaction.headTransactionId.substr(0, 8)}-...`}</>
                </TextField>
              </Grid>
            )}
            {party && (
              <Grid item xs={12}>
                <TextField label={`${t('account')} ${party.accountId}`}>
                  {party.accountNumber}
                </TextField>
              </Grid>
            )}
            {transaction.postingDate &&
              transaction.postedAmount && [
                <Grid item xs={6} key='postingDate'>
                  <TextField label={t('postingDate')}>
                    {moment(transaction.postingDate).format('ll')}
                  </TextField>
                </Grid>,
                <Grid item xs={6} key='postedAmount'>
                  <AmountField label={t('postedAmount')} amount={transaction.postedAmount} />
                </Grid>,
              ]}
            <Grid item xs={12} className={`${classes.bottomSpacing} ${classes.innerContend}`}>
              <Table>
                <TableBody>
                  {transaction
                    .entries!.filter((item) => item.category === 'TRANSACTION')
                    .sort((a, b) => {
                      if (a.category === '' || a === null) return 1;
                      if (b.category === '' || b === null) return -1;
                      if (a === b) return 0;
                      return a < b ? -1 : 1;
                    })
                    .map((item, index) => {
                      const { amount, category, description } = item;
                      return (
                        <AmoutRow
                          key={index}
                          category={
                            !description || description === category ? t(category) : description
                          }
                          amount={amount}
                        />
                      );
                    })}
                  {transaction
                    .entries!.filter(
                      (item) =>
                        item.category === 'FEE' ||
                        item.category === 'TAX' ||
                        item.category === 'ACQUIRING_FEE' ||
                        item.category === 'LOYALTY'
                    )
                    .map((item, index) => {
                      const { category, amount, description } = item;
                      return (
                        <AmoutRow
                          key={index}
                          category={description === category ? t(category) : description}
                          amount={amount}
                        />
                      );
                    })}
                  {transaction?.totalAmount && (
                    <AmoutRow category={t('totalAmount')} amount={transaction?.totalAmount} />
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {transaction.details.senderExternalSource && (
            <>
              <H3
                icon={
                  <SvgIcon>
                    <PocketIcon />
                  </SvgIcon>
                }
              >
                {t('externalSource._')}
              </H3>
              <Grid
                item
                container
                xs={12}
                spacing={2}
                className={`${classes.bottomSpacing} ${classes.innerContend}`}
              >
                <ExternalSourceReference
                  id={transaction.details.senderExternalSource.id}
                  type={transaction.details.senderExternalSource.type}
                />
              </Grid>
            </>
          )}
          {transaction.details.recipientExternalSource && (
            <>
              <H3
                icon={
                  <SvgIcon>
                    <PocketIcon />
                  </SvgIcon>
                }
              >
                {t('externalSource._')}
              </H3>
              <Grid
                item
                container
                xs={12}
                spacing={2}
                className={`${classes.bottomSpacing} ${classes.innerContend}`}
              >
                <ExternalSourceReference
                  id={transaction.details.recipientExternalSource.id}
                  type={transaction.details.recipientExternalSource.type}
                />
              </Grid>
            </>
          )}
          {!isEmpty(additionalData) && (
            <>
              <H3 icon={<InfoIcon />}>{t('additionalData')}</H3>
              <Grid
                item
                container
                spacing={2}
                className={`${classes.bottomSpacing} ${classes.innerContend}`}
              >
                {additionalData.consentId && (
                  <Grid item xs={12}>
                    <TextField label={t('consentId')} textWrap>
                      {additionalData.consentId}
                    </TextField>
                  </Grid>
                )}
                {(additionalData.response?.code || additionalData.response?.message) && (
                  <Grid item xs={12}>
                    <TextField label={t('response')} textWrap>
                      {(additionalData.response?.code ? `${additionalData.response?.code} ` : '') +
                        (additionalData.response?.message || '')}
                    </TextField>
                  </Grid>
                )}
                {additionalData.deal?.id && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('deal')}
                      showLink={`/deals/${additionalData.deal?.id}/show`}
                    >
                      {`${additionalData.deal?.id.substr(0, 23)}-...`}
                    </TextField>
                  </Grid>
                )}
                {additionalData.senderName && (
                  <Grid item xs={12}>
                    <TextField label={t('senderName')} textWrap>
                      {additionalData.senderName}
                    </TextField>
                  </Grid>
                )}
                {additionalData.recipientName && (
                  <Grid item xs={12}>
                    <TextField label={t('recipientName')} textWrap>
                      {additionalData.recipientName}
                    </TextField>
                  </Grid>
                )}
                {additionalData.recipientPhoneNumber && (
                  <Grid item xs={12}>
                    <TextField label={t('recipientPhoneNumber')} textWrap>
                      {additionalData.recipientPhoneNumber}
                    </TextField>
                  </Grid>
                )}
                {additionalData.comment && (
                  <Grid item xs={12}>
                    <TextField label={t('comment')} textWrap>
                      {additionalData.comment}
                    </TextField>
                  </Grid>
                )}
                {additionalData.location && (
                  <>
                    <Grid item xs={12}>
                      <TextField label={t('location')} textWrap>
                        {additionalData.location.name}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label={t('locationAddress')} textWrap>
                        <AddressField record={additionalData.location} source='address' />
                      </TextField>
                    </Grid>
                  </>
                )}
                {additionalData.employee && (
                  <Grid item xs={12}>
                    <ReferenceField
                      reference='employees'
                      basePath='/employees'
                      source='id'
                      record={additionalData.employee}
                      link={false}
                      addLabel={false}
                    >
                      <ShowController>
                        {({ showRecord }: { showRecord: Employee | undefined }) => {
                          const { firstName, lastName } = showRecord || {};
                          return (
                            <TextField
                              label={t('employee')}
                              textWrap
                            >{`${firstName} ${lastName}`}</TextField>
                          );
                        }}
                      </ShowController>
                    </ReferenceField>
                  </Grid>
                )}
                {additionalData.terminal && (
                  <Grid item xs={12}>
                    <TextField label={`${t('terminal')} ${additionalData.terminal.id}`} textWrap>
                      {additionalData.terminal.label}
                    </TextField>
                  </Grid>
                )}
                {additionalData.paymentToken && (
                  <Grid item xs={12}>
                    <TextField
                      label={`${t('paymentToken')} ${additionalData.paymentToken.type}`}
                      textWrap
                    >
                      {additionalData.paymentToken.value}
                    </TextField>
                  </Grid>
                )}
                {additionalData.merchantCategoryCode !== '0' && (
                  <Grid item xs={12}>
                    <TextField label={t('merchantCategoryCode')} multiline>
                      <MerchantCategoryCodeField
                        record={additionalData}
                        source='merchantCategoryCode'
                      />
                    </TextField>
                  </Grid>
                )}
                {additionalData.period && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField label={t('period')} textWrap>
                        {additionalData.period}
                      </TextField>
                    </Grid>
                  </Grid>
                )}
                {additionalData.referenceNumber && (
                  <Grid item xs={12}>
                    <TextField label={t('referenceNumber')} textWrap>
                      {additionalData.referenceNumber}
                    </TextField>
                  </Grid>
                )}
                {/*
                {additionalData.payer?.accountNumber && (
                  <Grid item xs={12}>
                    <TextField label={t('payerAccountNumber')} textWrap>
                      {additionalData.payer?.accountNumber}
                    </TextField>
                  </Grid>
                )}
                {additionalData.payer?.payerName && (
                  <Grid item xs={12}>
                    <TextField label={t('payerName')} textWrap>
                      {additionalData.payer?.payerName}
                    </TextField>
                  </Grid>
                )}
                {additionalData.payer?.bank && (
                  <Grid item xs={12}>
                    <TextField label={t('payerBank')} textWrap>
                      {additionalData.payer?.bank}
                    </TextField>
                  </Grid>
                )}
                */}
                {additionalData.referenceId &&
                  additionalData.referenceId.map((refId: any) => {
                    return (
                      <Grid item xs={12} key={refId.id}>
                        <TextField label={refId.id} textWrap>
                          {refId.value}
                        </TextField>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
          {transaction.details.service && (
            <TransactionServiceView transaction={transaction} target={target} />
          )}
        </Fragment>
      )}
    </Drawer>
  );
};

const getAdditionlData = (transaction: Transaction | null) => {
  if (!transaction) return {};
  return {
    consentId: transaction.consentId,
    // tradeName: transaction.details.tradeName,
    senderName: transaction.details.senderName,
    recipientName: transaction.details.recipientName,
    recipientPhoneNumber: transaction.details.recipientPhoneNumber,
    comment: transaction.details.comment,
    location: transaction.details.location,
    employee: transaction.details.employee,
    terminal: transaction.details.terminal,
    paymentToken: transaction.details.paymentToken,
    merchantCategoryCode: transaction.merchantCategoryCode,
    period: transaction.details?.membershipFee?.period,
    payer: transaction?.details?.payer,
    referenceNumber: transaction.details.referenceNumber,
    deal: transaction.details.deal || transaction.details.remittance,
    referenceId: transaction.details.referenceId,
    response: transaction.response,
    // purposeOfPayment: transaction.details.terminal?.label,
  };
};

export default TransactionView;
