import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2 } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './IntegrationTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';
import { Longdash, time } from '~/utils';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

const ResponsesSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`responses.${key}`));

  const responseEntries: Entry[] = [];
  if (trx.details.response?.code || trx.details.response?.message) {
    responseEntries.push({
      system: undefined,
      code: trx.details.response?.code,
      message: trx.details.response?.message,
    });
  }
  if (trx.details.response?.systems) {
    Object.entries(trx.details.response!.systems!).forEach(([system, response]) => {
      responseEntries.push({
        system,
        code: response.code,
        message: response.message,
        log: response.log,
      });
    });
  }

  return (
    <>
      <H2 topSpacing={0} bottomSpacing={2}>
        {t('_')}
      </H2>
      <Section className={`${classes.bottomSpacing}`}>
        <Grid container item xs={12}>
          <Table className={cx(classes.responsesTable, { [classes.autoWidth]: true })}>
            <TableHead>
              <TableRow>
                <TableCell width='5%' />
                <TableCell width='20%'>{t('system')}</TableCell>
                <TableCell width='25%'>{t('code')}</TableCell>
                <TableCell width='50%'>{t('message')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseEntries.length > 0 ? (
                responseEntries.map((entry) => <Row entry={entry} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center', height: '2.5em' }}>
                    {t('noResponses')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Section>
    </>
  );
};

type Entry = {
  system?: string;
  code?: string;
  message?: string;
  log?: {
    code?: string;
    message?: string;
    timestamp?: string;
  }[];
};

interface Props {
  entry: Entry;
}

const Row = (props: Props) => {
  const row = props.entry;
  const [open, setOpen] = React.useState(false);
  const hasLog = (row.log ?? []).length > 0;

  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow style={{ borderBottom: 0 }}>
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {!hasLog ? null : open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.system || Longdash}</TableCell>
        <TableCell>{row.code || Longdash}</TableCell>
        <TableCell>{row.message || Longdash}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table className={cx(classes.collapseTable)}>
              <TableBody>
                {row?.log?.map((historyRow) => (
                  <TableRow key={historyRow.code} style={{ borderBottom: 0 }}>
                    <TableCell width='5%' />
                    <TableCell width='20%'>
                      {historyRow.timestamp
                        ? time(historyRow.timestamp).format('LL HH:mm:ss')
                        : Longdash}
                    </TableCell>
                    <TableCell width='25%'>{historyRow.code || Longdash}</TableCell>
                    <TableCell width='50%'>{historyRow.message || Longdash}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ResponsesSection;
