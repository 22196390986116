import React, { useState, Fragment, FC } from 'react';
import { Button } from 'react-admin';
import { useMutation } from 'react-query';
import Accordion from '@material-ui/core/Accordion';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ViewList from '@material-ui/icons/ViewListOutlined';

import { closeAccount } from '~/api/accounts';
import { Drawer, H3, AccordionSummary, AccordionDetails, Confirmation } from '~/components';
import { SimpleForm } from '~/components/ra';
import { ProfileLimitPanel, useTranslateProfileLimits } from '~/components/ProfileLimits/index';
import Toolbar from '~/components/ra/Toolbar';
import { useGetAccountLimits, useGetAllAccounts, useGetProfileLimits } from '~/hooks/accounts';
import { Account } from '~/types';
import CloseButton from '../CloseButton';
import TextInput from '~/components/TextInput';
import Alert from '@material-ui/lab/Alert';

interface ViewOwnLimitsProps {
  profileId?: string;
  permissions: string[];
  resource: 'individuals' | 'businesses' | 'partners';
}

const useCloseAccount = () => {
  return useMutation(({ accountId, note }: { accountId: string; note: string }) =>
    closeAccount(accountId, 'accounts', note)
  );
};

const ViewOwnLimits: FC<ViewOwnLimitsProps> = (props) => {
  const [note, setNote] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleNote = ({ target }: any) => {
    setErrorMessage(undefined);
    setNote(target?.value);
  };

  const t = useTranslateProfileLimits();
  const classes = useStyles();

  const { profileId, permissions, resource } = props;
  const { data: accounts, refetch: refetchAccounts } = useGetAllAccounts({
    resource,
    profileId,
  });
  const { refetch: refetchProfileLimits } = useGetProfileLimits(profileId);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetchAccounts();
    refetchProfileLimits();
    setOpen(false);
    setCheckState({});
  };

  const [checkState, setCheckState] = useState<{ [x: string]: boolean }>({});
  const handleCheck = (key: string) => (checked: boolean) => {
    setCheckState((prev) => ({ ...prev, [key]: checked }));
  };
  const ownAccounts = accounts?.filter(
    (item: Account | undefined) => item?.profileId === profileId
  );
  const metaAccounts = ownAccounts?.filter(
    (item: Account | undefined) => item?.productTemplate?.category === 'CNL_ACCOUNT'
  );
  const activeAccounts = metaAccounts?.filter(({ status }) => status !== 'CLOSED') ?? [];
  const hasActiveAccounts = activeAccounts.length !== 0;
  const closedAccounts = metaAccounts?.filter(({ status }) => status === 'CLOSED') ?? [];
  const hasClosedAccounts = closedAccounts.length !== 0;

  const closeAccount = useCloseAccount();
  const handleDelete = () => {
    const ids = Object.entries(checkState)
      .filter(([, value]) => value)
      .map(([key]) => key);
    Promise.all(ids.map((accountId) => closeAccount.mutateAsync({ accountId, note })))
      .then(() => handleClose())
      .catch((e) => console.error(e));
  };

  let hasClosePermission = false;
  if (resource === 'businesses') {
    hasClosePermission = permissions.includes('business.account.close');
  } else if (resource === 'individuals') {
    hasClosePermission = permissions.includes('individual.account.close');
  } else if (resource === 'partners') {
    hasClosePermission = false; // permissions.includes('partner.account.create');
  }

  return (
    <Fragment>
      <Button label={t('viewOwn')} onClick={handleOpen}>
        <ViewList />
      </Button>
      <Drawer heading={t('ownProfileLimits')} open={open} onClose={handleClose}>
        <SimpleForm saving={closeAccount.isLoading} closeParent={handleClose} toolbar={null}>
          <Fragment>
            {hasActiveAccounts && (
              <>
                <H3 topSpacing={0} bottomSpacing={1}>
                  {t('activePackages')}
                </H3>
                {activeAccounts.map(({ id, alias, number }) => (
                  <AccountLimit
                    accountId={id}
                    alias={alias ?? number}
                    onCheck={(newCheck) => handleCheck(id)(newCheck)}
                  />
                ))}
              </>
            )}
            {hasActiveAccounts && hasClosePermission && (
              <Toolbar className={classes.ButtonContainer}>
                <Confirmation
                  confirmButtonProps={{
                    disabled: !note,
                  }}
                  confirmationSettings={{
                    variant: 'modal',
                    modal: {
                      heading: t('attention'),
                      content: (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Alert severity='warning'>{t('alert')}</Alert>
                          </Grid>
                          <Grid item xs={12}>
                            <TextInput
                              autoFocus
                              label={t('reason')}
                              onChange={handleNote}
                              value={note}
                              multiline
                              rows={5}
                              rowsMax={20}
                              error={!!errorMessage}
                              helperText={errorMessage}
                              required
                            />
                          </Grid>
                        </Grid>
                      ),
                    },
                  }}
                  onConfirm={() => {
                    handleDelete();
                    setNote('');
                  }}
                >
                  <CloseButton
                    red
                    disabled={Object.values(checkState).every((isCheck) => !isCheck)}
                    loading={closeAccount.isLoading}
                  />
                </Confirmation>
              </Toolbar>
            )}
            {hasClosedAccounts && (
              <>
                <H3 topSpacing={2} bottomSpacing={1}>
                  {t('closedPackages')}
                </H3>
                {closedAccounts.map(({ id, alias, number }) => (
                  <AccountLimit accountId={id} alias={alias ?? number} inactive />
                ))}
              </>
            )}
          </Fragment>
        </SimpleForm>
      </Drawer>
    </Fragment>
  );
};

interface AccountLimitProps {
  accountId: string;
  alias: string;
  onCheck?: (result: boolean) => void;
  inactive?: boolean;
}

const AccountLimit: FC<AccountLimitProps> = (props) => {
  const { accountId, inactive, alias, onCheck } = props;
  const { data } = useGetAccountLimits(accountId);
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked((prev) => {
      if (onCheck) onCheck(!prev);
      return !prev;
    });
  };

  const checkboxHeading = (
    <FormControlLabel
      onClick={(event) => event.stopPropagation()}
      label={alias}
      control={
        <Checkbox
          checked={isChecked}
          onChange={handleCheck}
          onClick={(event) => event.stopPropagation()}
        />
      }
    />
  );

  const plainHeading = <FormControlLabel label={alias} control={<div />} />;

  return (
    <Grid key={accountId} item xs={12}>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded((prev) => !prev)}
        className={inactive ? classes.InactiveAccordion : undefined}
      >
        <AccordionSummary
          aria-controls={`${accountId}-content`}
          id={`${accountId}-header`}
          expanded={isExpanded}
          heading={inactive ? plainHeading : checkboxHeading}
        />
        <AccordionDetails className={classes.AccordionDetails}>
          <ProfileLimitPanel limits={data ?? []} width={320} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(2, '!important'),
  },
  InactiveAccordion: {
    opacity: theme.palette.action.disabledOpacity,
  },
  ButtonContainer: {
    justifyContent: 'flex-end',
    padding: 0,
  },
}));

export default ViewOwnLimits;
