import React, { FC } from 'react';

import { ExportButton } from '~/components';
import BackgroundTask, { useBackgroundTaskTranslate } from '~/components/BackgroundTask';
import { HeadTransaction } from '~/types/HeadTransaction';

interface Props {
  headTransaction: HeadTransaction;
}

const ExportInvoice: FC<Props> = (props) => {
  const { headTransaction } = props;
  const t = useBackgroundTaskTranslate();

  const operationType = headTransaction.operationType;
  const dealOrRemittanceDetails =
    headTransaction.details.deal || headTransaction.details.remittance;

  const hasInvoiceBackwardCompatibility =
    dealOrRemittanceDetails &&
    dealOrRemittanceDetails?.senderCountry !== dealOrRemittanceDetails?.recipientCountry &&
    (operationType === 'REMITTANCE' ||
      operationType === 'BILL_PAYMENT' ||
      operationType === 'PAYOUT');

  const isOutbound =
    dealOrRemittanceDetails?.senderCountry === 'ZA' &&
    (operationType === 'REMITTANCE' ||
      operationType === 'BILL_PAYMENT' ||
      operationType === 'PAYOUT');

  const isInbound =
    dealOrRemittanceDetails?.recipientCountry === 'ZA' && operationType === 'INCOMING_TRANSFER';

  const hasInvoiceActual = isOutbound || isInbound;

  const hasCrossBorderTransferTag = headTransaction.tags?.includes('CROSS_BORDER_TRANSFER');
  const hasInvoice = hasCrossBorderTransferTag ? hasInvoiceActual : hasInvoiceBackwardCompatibility;

  if (!hasInvoice) {
    return null;
  }

  return (
    <BackgroundTask
      type='TENANT_INVOICE'
      queryArguments={{ headTransactionId: headTransaction?.id }}
      dialogTitle={t('downloadPDF')}
      dialogType='export'
    >
      <ExportButton label={t('invoice')} />
    </BackgroundTask>
  );
};

export default ExportInvoice;
