import React, { FC, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TextField, StatusField } from '~/components';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { AsideBase } from '~/layout';
import DeleteAction from './DeleteAction';
import EditAction from './EditAction';
import { time } from '~/utils';
import LinkButton from './LinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  editable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  leftSpacing: {
    marginLeft: theme.spacing(4),
  },
  text: {
    '& *': {
      fontWeight: 400,
    },
  },
  actions: {
    justifyContent: 'right',
  },
}));

interface Props {
  link: ExternalProfileLink;
  width?: number;
  permissions?: any;
}

export const ExternalProfileLinkCard: FC<Props> = (props) => {
  const { link, width, permissions } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ExternalProfileLinks.${key}`);

  const classes = useStyles();

  const hasUpdatePermission = permissions?.includes('externalProfileLink.update');
  const hasDeletePermission = permissions?.includes('externalProfileLink.delete');
  const hasViewPermission = permissions?.includes('externalProfileLink.view');

  return (
    <AsideBase width={width} className={classes.root}>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField label={t('sourceSystem')}>{link.externalSourceSystem}</TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('status')}>
              <StatusField status={link.status} />
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('lastUpdate')}>
              {link.updated && time(link.updated).format('lll')}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t('referenceId')}>
              {link.referenceId?.map((referenceId) => (
                <Fragment key={referenceId}>
                  {referenceId}
                  <br />
                </Fragment>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{ marginTop: 8 }} className={classes.actions}>
          {hasUpdatePermission && (
            <Grid item>
              <EditAction link={link} />
            </Grid>
          )}
          {hasDeletePermission && (
            <Grid item>
              <DeleteAction link={link} />
            </Grid>
          )}
          {hasViewPermission && (
            <Grid item>
              <LinkButton link={link} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AsideBase>
  );
};

export default ExternalProfileLinkCard;
