import React, { useState } from 'react';
import { JSONSchemaType } from 'ajv';
import { Tab, useShowController, useTranslate, usePermissions } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { time } from '~/utils';
import cx from 'classnames';
import ajv from '~/utils/ajv';
import { buildName } from '~/utils';
import { Show, PaperTabbedShowLayout } from '~/layout';
import { EditAccountInfo, Aside, ApiIdentityTab } from './components';
import {
  Accounts,
  TextField,
  NoPermissions,
  CheckPermissions,
  ShowController,
  Cards,
  ExternalSources,
} from '~/components/ra';
import {
  TransactionsHistory,
  H2,
  TextField as TextFieldComponent,
  PhoneField,
  DocumentCard,
  Notes,
  AuditLogWidget,
  UpdateContext,
  AddressTable,
  RecordTitle,
  CountryField,
  ProfileTags,
} from '~/components';
import { EditAddress } from '~/components';
import { EditContacts } from '~/components';
import { Individual } from '~/types/individual';
import BeneficiariesList from '../beneficiaries/BeneficiariesList';
import { useConfiguration, useDeepEffect, useSolution } from '~/hooks';
import { useGetChangelogData, useGetChangelogsData } from '~/hooks/useGetChangelogsData';
import { Alert, Skeleton } from '@material-ui/lab';
import JsonSchemaView from '~/components/JsonSchemaView';
import EditAdditionalProperties from './components/EditAdditionalProperties';
import AddOrEditDocument from './components/AddOrEditDocument';
import ProfileLimits from '~/components/ProfileLimits';
import { useRightContentSpacing } from '~/hooks/styles';
// import { useApiIdentityByProfileId } from '~/hooks/apiIdentity';
import ExternalProfileLinks from '~/components/ExternalProfileLinks';
import EventLogWidget from '~/components/Events/EventLogWidget';
import EventLogKYC from '~/components/Events/EventLogKYC';
import ProfileHistoryDrawer from '~/components/ProfileHistoryDrawer';
import palette from '~/theme/palette';
import AffiliatedObjects from '~/components/AffiliatedObjects';

const label = (key: string): string => `resources.individuals.fields.${key}`;

const Layout = (props: any) => {
  const { history, resource, record, changelogMode, selectedChangelogTime } = props;
  const currentPathname = history?.location.pathname;
  // const apiIdentityPathname = `/${resource}/${record?.id || ''}/show/apiIdentity`;
  const eventLogPathname = `/${resource}/${record?.id || ''}/show/eventLog`;
  const systemLogPathname = `/${resource}/${record?.id || ''}/show/systemLog`;

  // const apiIdentitiesQuery = useApiIdentityByProfileId({ profileId: record?.id });
  // const apiIdentity = apiIdentitiesQuery.data && apiIdentitiesQuery.data[0];
  const { permissions } = usePermissions();

  return (
    <PaperTabbedShowLayout
      {...props}
      changelogMode={changelogMode}
      bottomContent={
        currentPathname === eventLogPathname && permissions?.includes?.('individual.event.list') ? (
          <EventLogWidget
            objectId={record.id}
            objectType='individuals'
            history={history}
            changelogMode={changelogMode}
            selectedChangelogTime={selectedChangelogTime}
          />
        ) : /*currentPathname === apiIdentityPathname && apiIdentity?.id ? (
          <AuditLogWidget identityId={apiIdentity.id} tags={['s.apiIdentity']} history={history} />
        ) : */ currentPathname ===
          systemLogPathname ? (
          <AuditLogWidget profileId={record.id} history={history} />
        ) : null
      }
    />
  );
};

interface ProfileSchema {
  schema?: JSONSchemaType<Record<string, any>>;
  error?: boolean;
}

const IndividualShow = (props: any) => {
  const { resource, basePath, history } = props;
  // States & handlers for ChangeLog.
  const [open, setOpen] = useState(false);
  const [changelogMode, setChangelogMode] = useState(false);
  const [selectedChangelog, setSelectedChangelog] = useState<number | undefined>(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTurnOnChangelogMode = (id: number) => {
    setChangelogMode(true);
    setSelectedChangelog(id);
    handleClose();
  };
  const handleTurnOffChangelogMode = () => {
    setChangelogMode(false);
    setSelectedChangelog(undefined);
  };

  const { permissions } = usePermissions();
  const hasChangelogPermissionList = permissions?.includes('individual.changelog.list');
  const hasChangelogPermissionView = permissions?.includes('individual.changelog.view');

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const showController = useShowController(props);

  const { data: selectedChangelogData } = useGetChangelogData(
    resource,
    showController?.record?.id!,
    !!hasChangelogPermissionList && !!selectedChangelog,
    selectedChangelog
  );

  // Timestamp for event logs.
  const selectedChangelogTime = changelogMode ? selectedChangelogData?.timestamp : undefined;

  // Record data for individual.
  const showRecord: Individual | undefined =
    changelogMode && selectedChangelogData ? selectedChangelogData?.data : showController.record;

  const currentPathname = history?.location.pathname;
  const accountsPathname = `/${resource}/${showRecord?.id || ''}/show`;
  const profilePathname = `/${resource}/${showRecord?.id || ''}/show/profile`;

  // Fetch the changelogs data if user has permissions.

  const { isLoading: changelogsLoading, data: changelogsData, refetch } = useGetChangelogsData(
    resource,
    showRecord?.id!,
    !!hasChangelogPermissionList && currentPathname === profilePathname
  );

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  const { data: solution } = useSolution(resource.solutionId);

  const [profileSchema, setProfileSchema] = useState<ProfileSchema>();
  const { data: configuration } = useConfiguration(showRecord?.solutionId);
  useDeepEffect(() => {
    if (configuration) {
      const schemaRaw: string | undefined = configuration?.veengu_individual_profile?.profileSchema;
      if (schemaRaw) {
        try {
          const anySchema = JSON.parse(schemaRaw) as any;
          if (anySchema.type !== 'object') {
            throw new Error('Json schema type !== object');
          }
          const schema = anySchema as JSONSchemaType<Record<string, any>>;
          ajv.compile(schema);
          console.log(`using individual profile schema: `, schema);
          setProfileSchema({ schema });
        } catch (e) {
          console.error(`failed to parse individual profile schema`, e);
          setProfileSchema({ error: true });
        }
      } else {
        console.error('no individual profile schema provided');
        setProfileSchema({ error: true });
      }
    }
  }, [configuration]);

  // Prevent undefined check in child component.
  if (!showRecord) return null;
  if (!permissions) return null;
  if (!permissions.includes('individual.view')) return <NoPermissions />;

  const hasBeneficiarySupport =
    solution?.features?.includes('veengu_individual_beneficiary_management') &&
    permissions?.includes('beneficiary.list');

  const hasCardIssuingSupport = solution?.features?.includes('veengu_individual_card_issuing');

  const hasExternalProfileLinksPermission = permissions?.includes('externalProfileLink.list');

  const hasTagsViewPermission = permissions?.includes('individual.tags.view');
  return (
    <Show
      {...props}
      title={
        <RecordTitle
          headline={(record: Individual, resource: 'individuals') =>
            buildName(resource, record) || '???'
          }
          record={showRecord}
          avatarSource='icon'
          resource='individuals'
          openDrawer={handleOpen}
          hasChangelogPermissionList={hasChangelogPermissionList}
          hasChangelogPermissionView={hasChangelogPermissionView}
          hasPathnameProfile={currentPathname === profilePathname}
          changelogMode={changelogMode}
          handleTurnOffChangelogMode={handleTurnOffChangelogMode}
          changelogsData={changelogsData || []}
          setSelectedChangelog={setSelectedChangelog}
          selectedChangelog={selectedChangelog}
          refetch={refetch}
        />
      }
    >
      {showRecord && <UpdateContext data={{ solutionId: showRecord.solutionId }} />}
      <Layout
        history={props.history}
        changelogMode={changelogMode}
        selectedChangelogTime={selectedChangelogTime}
      >
        <Tab
          className={classes.tab}
          label='resources.individuals.tabs.accounts'
          disabled={!permissions?.includes('individual.account.view') || changelogMode}
        >
          <Accounts
            resource='individuals'
            profileId={showRecord?.id || ''}
            permissions={permissions}
            basePath={basePath}
            solutionId={showRecord?.solutionId}
          />
          <ExternalSources
            profileId={showRecord?.id || ''}
            solutionId={showRecord?.solutionId}
            resource='individuals'
          />
          {hasCardIssuingSupport && (
            <Cards
              profileId={showRecord?.id || ''}
              solutionId={showRecord?.solutionId}
              resource='individuals'
            />
          )}
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.individuals.tabs.profile'
          path='profile'
          disabled={!permissions?.includes('individual.view')}
        >
          {hasChangelogPermissionList && (
            <ProfileHistoryDrawer
              open={open}
              handleClose={handleClose}
              translate={translateLabel}
              handleTurnOnChangelogMode={handleTurnOnChangelogMode}
              changelogsData={changelogsData}
              changelogsLoading={changelogsLoading}
              hasChangelogPermissionView={hasChangelogPermissionView}
              selectedChangelog={selectedChangelog}
            />
          )}
          <CheckPermissions permission='individual.view'>
            <div className={classes.section}>
              <header className={classes.header}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translateLabel('profileInfo')}
                </H2>
                {!changelogMode && (
                  <EditAccountInfo
                    permissions={permissions}
                    record={showRecord}
                    resource={resource}
                  />
                )}
              </header>
              <div className={classes.container}>
                <Grid container spacing={2}>
                  <Grid xs spacing={2} item container direction='column'>
                    <Grid item>
                      <TextField
                        source='firstName'
                        label={translateLabel('firstName')}
                        record={showRecord}
                      />
                    </Grid>
                    <Grid item>
                      <TextFieldComponent label={translateLabel('birthDate')}>
                        {showRecord?.birthDate && time(showRecord?.birthDate).format('L')}
                      </TextFieldComponent>
                    </Grid>
                    <Grid item>
                      <TextField
                        source='placeOfBirth'
                        label={translateLabel('placeOfBirth')}
                        record={showRecord}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs spacing={2} item container direction='column'>
                    <Grid item>
                      <TextField
                        source='lastName'
                        label={translateLabel('lastName')}
                        record={showRecord}
                      />
                    </Grid>
                    <Grid item>
                      <TextFieldComponent label={translateLabel('gender._')}>
                        {showRecord?.gender && translateLabel(`gender.${showRecord.gender}`)}
                      </TextFieldComponent>
                    </Grid>
                  </Grid>
                  <Grid xs spacing={2} item container direction='column'>
                    <Grid item>
                      <TextField
                        source='addNames[0]'
                        label={translateLabel('middleName')}
                        record={showRecord}
                      />
                    </Grid>
                    <Grid item>
                      <TextField label={translateLabel('nationality')}>
                        <CountryField>{showRecord.nationality}</CountryField>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={cx(classes.section, classes.topSpacing3)}>
              <header className={classes.header}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translateLabel('contacts')}
                </H2>
                {!changelogMode && (
                  <EditContacts
                    permissions={permissions}
                    resource='individuals'
                    record={showRecord}
                  />
                )}
              </header>
              <div className={classes.container}>
                {!!showRecord?.contacts && showRecord?.contacts.length > 0 ? (
                  <Grid container spacing={2}>
                    {showRecord?.contacts?.map((item, index) => {
                      const { id, channel } = item;
                      if (channel === 'PHONE') {
                        return (
                          <Grid xs={4} item key={index}>
                            <TextFieldComponent
                              label={translate(`components.ra.inputs.ChannelSelect.${channel}`)}
                            >
                              <PhoneField number={id} />
                            </TextFieldComponent>
                          </Grid>
                        );
                      }
                      return (
                        <Grid xs={4} item key={index}>
                          <TextFieldComponent
                            label={translate(`components.ra.inputs.ChannelSelect.${channel}`)}
                          >
                            {id}
                          </TextFieldComponent>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  translateLabel('noContacts')
                )}
              </div>
            </div>
            <div className={cx(classes.section, classes.topSpacing3)}>
              <header className={classes.header}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translateLabel('address._')}
                </H2>
                {!changelogMode && (
                  <EditAddress
                    permissions={permissions}
                    resource='individuals'
                    record={showRecord}
                  />
                )}
              </header>
              <div className={classes.container}>
                {showRecord?.address ? (
                  <Grid container>
                    <Grid xs={12} item>
                      <AddressTable address={showRecord.address} changelogMode={changelogMode} />
                    </Grid>
                  </Grid>
                ) : (
                  translateLabel('noAddress')
                )}
              </div>
            </div>
            <div className={cx(classes.section, classes.topSpacing3)}>
              <header className={classes.header}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translateLabel('documents._')}
                </H2>
                {!changelogMode && (
                  <AddOrEditDocument record={showRecord} permissions={permissions} />
                )}
              </header>
              <div className={classes.container}>
                {showRecord?.documents && showRecord?.documents.length > 0 ? (
                  <Grid className={classes.noneMaxWidth} container spacing={2}>
                    {showRecord?.documents?.map((document, index) => {
                      return (
                        <Grid item xs={6} xl={5} key={index}>
                          <DocumentCard
                            resource='individuals'
                            record={showRecord}
                            solutionId={showRecord?.solutionId}
                            country={showRecord?.country}
                            documentFamily='INDIVIDUAL'
                            key={index}
                            index={index}
                            document={document}
                            fullWidth
                            permissions={permissions}
                            editDocument={(index, primary, handleClose) => (
                              <AddOrEditDocument
                                record={showRecord}
                                edit
                                primary={primary}
                                index={index}
                                closeParentDrawer={handleClose}
                                permissions={permissions}
                              />
                            )}
                            changelogMode={changelogMode}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  translateLabel('noDocuments')
                )}
              </div>
            </div>
            <div className={cx(classes.section, classes.topSpacing3)}>
              <header className={classes.header}>
                <H2 topSpacing={0} bottomSpacing={0}>
                  {translateLabel('additionalProperties._')}
                </H2>
                {profileSchema?.schema &&
                  !changelogMode &&
                  Object.keys(profileSchema.schema.properties || {}).length > 0 && (
                    <EditAdditionalProperties
                      permissions={permissions}
                      record={showRecord!}
                      schema={profileSchema.schema}
                    />
                  )}
              </header>
              <div className={classes.container}>
                {profileSchema?.schema ? (
                  Object.keys(profileSchema.schema.properties || {}).length > 0 ? (
                    <JsonSchemaView
                      data={showRecord}
                      schema={profileSchema?.schema}
                      displayMissingProperties
                      className={classes.noneMaxWidth}
                    />
                  ) : (
                    <>
                      {translateLabel('additionalProperties.empty')}
                      <br />
                      <br />
                    </>
                  )
                ) : profileSchema?.error ? (
                  <Alert severity='warning' style={{ marginBottom: '1em' }}>
                    {translateLabel('additionalProperties.badSchema')}
                  </Alert>
                ) : (
                  <Skeleton variant='text' width={200} height={24} />
                )}
              </div>
            </div>
          </CheckPermissions>
        </Tab>
        <Tab
          className={classes.tab}
          label='resources.individuals.tabs.transactionHistory'
          path='transactions'
          disabled={!permissions.includes('individual.transaction.list') || changelogMode}
        >
          <TransactionsHistory permissions={permissions} target='individuals' record={showRecord} />
        </Tab>
        {hasBeneficiarySupport && (
          <Tab
            className={classes.tab}
            label='resources.individuals.tabs.beneficiaries'
            path='beneficiaries'
            disabled={changelogMode}
          >
            <BeneficiariesList record={showRecord} resource={resource} permissions={permissions} />
          </Tab>
        )}
        {permissions?.includes('individual.view') &&
          permissions?.includes('individual.event.list') && (
            <Tab
              className={classes.tab}
              label='resources.individuals.tabs.eventLog'
              path='eventLog'
              disabled={changelogMode}
            />
          )}
        {permissions?.includes('apiIdentity.list') && (
          <Tab
            className={classes.tab}
            label='resources.individuals.tabs.apiIdentity'
            path='apiIdentity'
            disabled={changelogMode}
          >
            <ApiIdentityTab record={showRecord} />
          </Tab>
        )}
        {permissions?.includes('auditLog.list') && (
          <Tab
            className={classes.tab}
            label='resources.individuals.tabs.systemLog'
            path='systemLog'
            disabled={changelogMode}
          />
        )}
        {permissions?.includes('individual.view') &&
          permissions?.includes('individual.event.list') && (
            <Tab
              className={classes.tab}
              label='resources.individuals.tabs.checks'
              path='checks'
              disabled={changelogMode}
            >
              <EventLogKYC objectType='individual' object={showRecord} />
            </Tab>
          )}
      </Layout>
      <ShowController>
        {({ showRecord, ...rest }: any) => {
          const record =
            changelogMode && selectedChangelogData ? selectedChangelogData?.data : showRecord;
          return (
            <Grid container item xs direction='column' spacing={spacing}>
              <Grid item className={classes.rightContent}>
                <Aside record={record} {...rest} changelogMode={changelogMode} />
              </Grid>
              {(currentPathname === accountsPathname || currentPathname === profilePathname) &&
                hasTagsViewPermission && (
                  <Grid item className={classes.rightContent}>
                    <ProfileTags
                      profileId={showRecord.id}
                      resource='individuals'
                      permissions={permissions}
                      record={record}
                      changelogMode={changelogMode}
                    />
                  </Grid>
                )}
              {currentPathname === accountsPathname && (
                <Grid item className={classes.rightContent}>
                  <ProfileLimits
                    profileId={showRecord.id}
                    resource='individuals'
                    solutionId={showRecord?.solutionId}
                    permissions={permissions}
                  />
                </Grid>
              )}
              {currentPathname === profilePathname && showRecord.references && (
                <AffiliatedObjects
                  id={showRecord.id}
                  references={record?.references}
                  resource='individuals'
                  changelogMode={changelogMode}
                />
              )}
              {(currentPathname === accountsPathname || currentPathname === profilePathname) &&
                hasExternalProfileLinksPermission && (
                  <Grid item className={classes.rightContent}>
                    <ExternalProfileLinks
                      profileId={showRecord.id}
                      profileType='INDIVIDUAL'
                      permissions={permissions}
                    />
                  </Grid>
                )}
              {currentPathname === profilePathname && !changelogMode && (
                <Grid item className={cx(classes.rightContent, classes.fullWidthGrow)}>
                  <Notes referenceId={showRecord.id} profileType='INDIVIDUAL' />
                </Grid>
              )}
            </Grid>
          );
        }}
      </ShowController>
    </Show>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  tab: {
    minWidth: 80,
    flexGrow: 1,
    maxWidth: 200,
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
  topSpacing3: {
    marginTop: ({ spacing }) => theme.spacing(spacing),
  },
  container: {
    paddingLeft: ({ spacing }) => theme.spacing(spacing),
    paddingRight: ({ spacing }) => theme.spacing(spacing),
  },
  header: {
    marginBottom: ({ spacing }) => theme.spacing(spacing),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noneMaxWidth: {
    maxWidth: 'none',
  },
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
  changeLogMode: {
    backgroundColor: palette.warning.main,
    transition: `${theme.transitions.easing.easeIn}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
  },
}));

export default IndividualShow;
