import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import { Beneficiary } from '~/types/beneficiaries';
import { CountryField, TextField } from '~/components';
import { l } from './BeneficiaryInfo';
import ReferenceField from '~/components/ReferenceField';
import { Longdash } from '~/utils';
import { getBeneficiaryName } from '~/utils/beneficiaries';

interface Props {
  record: Beneficiary | undefined;
  type: string;
}

const BeneficiaryPersonalInfo: FC<Props> = (props) => {
  const { record, type } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(l(key));
  if (!record) return null;
  const name = getBeneficiaryName(record);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {record?.profileId && (record?.type || record?.profileType) ? (
          <ReferenceField
            record={{ id: record?.profileId, type: record?.type || record?.profileType }}
          />
        ) : (
          <TextField label={t(record?.profileType || record?.type || 'typeNotDefined')}>
            {name || Longdash}
          </TextField>
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('countryOfResidence')}>
          <CountryField>{record?.country}</CountryField>
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <Chip label={t(type)} />
      </Grid>
    </Grid>
  );
};

export default BeneficiaryPersonalInfo;
