export default {
  name: 'Transaction |||| Transactions',
  subtitle: 'All transactions (bank account transfers are not included)',
  list: {
    title: 'Transactions',
    sender: 'Sender',
    recipient: 'Recipient',
    created: 'Date and time',
    baseAmount: 'Base amount',
    instructedAmount: 'Instructed amount',
    operationType: 'Operation type',
    status: 'Status',
    reportingStatus: 'Reporting status',
    settled: 'Settled',
    filters: {
      from: 'Date & time from',
      to: 'Date & time to',
      senderAccountNumber: 'Sender (account number)',
      recipientAccountNumber: 'Recipient (account number)',
      currency: 'Currency',
      baseAmountFrom: 'Base amount from',
      baseAmountTo: 'Base amount to',
      referenceId: 'Reference ID',
      reportStatus: 'Report status',
      reportSystem: 'Report system',
      transactionStatus: 'Transaction status',
      settled: 'Is settled?',
      payerSolutionId: 'Payer solution ID',
      payeeSolutionId: 'Payee solution ID',
    },
  },
  tabs: {
    details: 'Details',
    context: 'Context',
    integration: 'Integration data',
    actionReports: 'Action reporting',
  },
  aside: {
    details: {
      created: 'Transaction date',
      postingDate: 'Posting date',
      status: 'Status',
      referenceId: 'Reference IDs',
      reporting: 'Reporting',
      tags: 'Transaction tags',
      deal: 'Deal',
      reversalTransaction: 'Reversal transaction',
      revertedTransaction: 'Reverted transaction',
      approvalTransaction: 'Approval transaction',
      approvedTransaction: 'Approved transaction',
      settlementTransaction: 'Settlement transaction',
      settledTransaction: 'Settled transaction',
      payroll: 'Payroll',
    },
  },
  show: {
    details: {
      transactionDetails: 'Transaction details',
      transfers: 'Transfers plan',
      bankAccountTransfers: 'Consolidated account transfers',
      created: 'Date and time',
      postingDate: 'Posting date',
      operationType: 'Operation type',
      operationSubtype: 'Operation subtype',
      status: 'Status',
      baseAmount: 'Base amount',
      instructedAmount: 'Instructed amount',
      deal: 'Deal',
      referenceIds: 'Reference IDs',
      sender: 'Sender (Debit)',
      recipient: 'Recipient (Credit)',
      originator: 'Originator',
      payer: 'Payer',
      payee: 'Payee',
      individual: 'Individual',
      business: 'Business',
      partner: 'Partner',
      tenant: 'Tenant',
      account: 'Veengu account',
      transfer: {
        amount: 'Amount',
        from: 'Sender account',
        to: 'Recipient account',
        type: 'Transfer type',
      },
      senderAmounts: 'Sender amounts',
      recipientAmounts: 'Recipient amounts',
      entry: {
        category: 'Type',
        amount: 'Amount',
        transactionAmount: 'Transfer amount',
        totalAmount: 'Total amount',
      },
      bankTransfer: {
        amount: 'Amount',
        from: 'Sender bank account',
        to: 'Recipient bank account',
      },
      revertTenantFees: 'Revert tenant fees & taxes',
      reversalParameters: 'Reversal parameters',
      revert: 'Revert transaction',
      revertDialogTitle: 'Revert transaction',
      revertSuccess: 'Transaction successfully reverted',
      cancel: 'Cancel',
      confirm: 'Confirm',
      reason: 'Reason',
      goToTransaction: 'Go to transaction',
      closeDialog: 'Close',
      revertDanger: `You are creating a reversal transaction. Original transaction amount will be reversed to sender account excluding collected fees & taxes. In case of not sufficient funds, there will be an overdraft on the sender (original recipient) account.
      Be sure you understand of impact on account balances.
      This action is not reversible!`,
      revertDangerWithFees: `You are creating a reversal transaction. Original transaction amount will be reversed to sender account including collected fees & taxes. In case of not sufficient funds, there will be an overdraft on the sender (original recipient) account.
      Be sure you understand of impact on account balances.
      This action is not reversible!`,
      hasPartner:
        "Caution: This transaction involves third-party interaction. Ensure alignment with their system's status.",
      termsAndConditionsVersion: 'Terms and conditions version',
      senderExchangeRate: 'Sender FX rate',
      recipientExchangeRate: 'Recipient FX rate',
      instructedExchangeRate: 'FX rate for instructed amount',
      response: 'Response',
      amount: 'Transaction amount',
      tags: 'Transaction tags',
      beneficiaryAmount: 'Beneficiary amount',
      approve: 'Approve transaction',
      approveDialogTitle: 'Approve transaction',
      approveSuccess: 'Transaction successfully approved',
      approveDanger: `You are creating an approval transaction. All original transaction transfers will be performed.
      Be sure you understand of impact on account balances.
      This action is not reversible!`,
      accountOwner: 'Profile of Veengu account',
      noBankTransfers: 'There are no consolidated account transfers.',
      externalSource: 'External source',
      cancelTransaction: 'Cancel transaction',
      cancelDanger:
        'Cancelling a transaction reverts all financial impacts of the direct transaction and requests to third-party services.',
      cancelDialogTitle: 'Cancel transaction',
      cancelSuccess: 'Transaction successfully cancelled',
    },
    context: {
      service: {
        _: 'Service',
        partner: 'Partner',
        integrationPlugin: 'Integration plugin',
        parameter: 'Parameter',
        data: 'Data',
      },
      serviceCharge: {
        _: 'Service charge',
        deliveryAddress: 'Delivery address',
        id: 'ID',
        name: 'Name',
      },
      originator: {
        _: 'Originator',
        tradeName: 'Trade name',
        employee: 'Team member',
        location: 'Location',
        terminal: 'Terminal',
        paymentToken: {
          STATIC: 'Payment token (static)',
          DYNAMIC: 'Payment token (dynamic)',
        },
        mcc: 'MCC',
        unknownOriginator: 'Unknown',
      },
      senderExternalSource: 'Sender external source',
      recipientExternalSource: 'Recipient external source',
      externalSource: {
        name: 'Name',
        type: 'Type',
        parameter: 'Parameter',
        data: 'Data',
      },
      otherParameters: {
        _: 'Other parameters',
        termsAndConditions: 'Terms and conditions',
        payer: 'payer',
        comment: 'Comment',
        recipientName: 'Recipient name',
        recipientPhoneNumber: 'Recipient phone number',
      },
    },
    integration: {
      responses: {
        _: 'Responses',
        system: 'Third-party',
        code: 'Code',
        message: 'Message',
        noResponses: 'There are no responses from third-party systems.',
      },
      otherTechnicalData: {
        _: 'Other technical data',
        parameter: 'Parameter',
        data: 'Data',
        noOtherTechnicalData: 'There is no other technical data for transaction.',
      },
    },
  },
};
