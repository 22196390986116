import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { H2, TextField } from '~/components';
import { Section } from '~/layout';
import { makeLabel } from './ContextTab';
import useStyles from './useStyles';
import { HeadTransaction } from '~/types/HeadTransaction';

const OtherParametersSection = (props: { trx: HeadTransaction }) => {
  const { trx } = props;

  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(makeLabel(`otherParameters.${key}`));

  const termsAndConditions = trx?.details.termsAndConditions;
  const comment = trx?.details.comment;
  const recipientName = trx?.details.recipientName;
  const recipientPhoneNumber = trx?.details.recipientPhoneNumber;

  const hasData =
    [termsAndConditions, comment, recipientName, recipientPhoneNumber].filter((item) => !!item)
      .length > 0;

  return hasData ? (
    <>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('_')}
        </H2>
      </header>
      <Section className={`${classes.footerBttomSpacing}`}>
        <Grid container spacing={2}>
          {termsAndConditions && (
            <Grid item xs={4}>
              <TextField label={t('termsAndConditions')}>
                {`${termsAndConditions?.version} (${
                  termsAndConditions?.party ? termsAndConditions?.party : t('payer')
                })`}
              </TextField>
            </Grid>
          )}
          {comment && (
            <Grid item xs={4}>
              <TextField multiline label={t('comment')}>
                {comment}
              </TextField>
            </Grid>
          )}
          {recipientName && (
            <Grid item xs={4}>
              <TextField label={t('recipientName')}>{recipientName}</TextField>
            </Grid>
          )}
          {recipientPhoneNumber && (
            <Grid item xs={4}>
              <TextField label={t('recipientPhoneNumber')}>{recipientPhoneNumber}</TextField>
            </Grid>
          )}
        </Grid>
      </Section>
    </>
  ) : null;
};

export default OtherParametersSection;
