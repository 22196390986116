import { Beneficiary } from '~/types/beneficiaries';

export const getBeneficiaryName = (beneficiary: Beneficiary) => {
  switch (beneficiary?.type) {
    case 'INDIVIDUAL':
      return getName(beneficiary?.firstName, beneficiary?.lastName);
    case 'BUSINESS':
      return beneficiary?.document?.legalType
        ? getName(beneficiary?.name, beneficiary?.document?.legalType)
        : beneficiary?.name;
    default:
      return beneficiary?.name
        ? beneficiary?.name
        : beneficiary?.firstName && beneficiary?.lastName
        ? getName(beneficiary?.firstName, beneficiary?.lastName)
        : '';
  }
};

const getName = (...args: string[]) => {
  if (args && args.length) {
    return args.filter(Boolean).join(' ');
  }
  return '';
};
