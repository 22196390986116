import React, { useState } from 'react';
import {
  blockAccount as apiBlockAccount,
  unblockAccount as apiUnblockAccount,
  closeAccount as apiCloseAccount,
} from '~/api/accounts';
import { Account, ExternalSource } from '~/types';
import { BlockButton, CloseButton, Confirmation, UnblockButton } from '.';
import { usePermissions } from 'react-admin';
import onError from '~/errorsHandler';
import TextInput from '~/components/TextInput';
import { Grid } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import Alert from '@material-ui/lab/Alert';

interface Props {
  status: 'ACTIVE' | 'BLOCKED' | 'CLOSED';
  resource?: 'businesses' | 'individuals';
  type: 'accounts' | 'externalSources';
  record: Account | ExternalSource;
  refetch: () => void;
}

const AccountsActions = (props: Props) => {
  const { status, resource, record, refetch, type } = props;

  const translate = useTranslate();
  const t = (key: string, args?: any): string =>
    translate(`resources.accounts.actions.${key}`, args);

  const { permissions } = usePermissions();
  const hasAccountBlockPermission = permissions?.includes('account.block');
  let hasBlockPermission = false;
  let hasUnblockPermission = false;
  let hasClosePermission = false;
  if (type === 'accounts') {
    if (hasAccountBlockPermission) {
      hasBlockPermission = true;
      hasUnblockPermission = true;
      hasClosePermission = true;
    } else if (resource === 'businesses') {
      hasBlockPermission = permissions?.includes('business.account.block');
      hasUnblockPermission = permissions?.includes('business.account.unblock');
      hasClosePermission = permissions?.includes('business.account.close');
    } else if (resource === 'individuals') {
      hasBlockPermission = permissions?.includes('individual.account.block');
      hasUnblockPermission = permissions?.includes('individual.account.unblock');
      hasClosePermission = permissions?.includes('individual.account.close');
    } else if (resource === 'partners') {
      hasBlockPermission = false; //permissions?.includes('partner.account.block');
      hasUnblockPermission = false; //permissions?.includes('partner.account.unblock');
      hasClosePermission = false; //permissions?.includes('partner.account.close');
    } else {
      hasBlockPermission = false;
      hasUnblockPermission = false;
      hasClosePermission = false;
    }
  } else if (type === 'externalSources') {
    hasBlockPermission = permissions?.includes('externalSource.block');
    hasUnblockPermission = permissions?.includes('externalSource.unblock');
    hasClosePermission = permissions?.includes('externalSource.close');
  }

  const blockAccount = (
    accountId: string,
    accountType: 'accounts' | 'externalSources',
    note: string
  ) => {
    apiBlockAccount(accountId, accountType, note).catch(onError).finally(refetch);
  };

  const unblockAccount = (
    accountId: string,
    accountType: 'accounts' | 'externalSources',
    note: string
  ) => {
    apiUnblockAccount(accountId, accountType, note).catch(onError).finally(refetch);
  };

  const closeAccount = (
    accountId: string,
    accountType: 'accounts' | 'externalSources',
    note: string
  ) => {
    apiCloseAccount(accountId, accountType, note).catch(onError).finally(refetch);
  };

  const [note, setNote] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleNote = ({ target }: any) => {
    setErrorMessage(undefined);
    setNote(target?.value);
  };
  if (!resource) return null;
  return (
    <>
      {status === 'ACTIVE' && hasBlockPermission && (
        <Confirmation
          onConfirm={() => {
            blockAccount(record.id, type, note);
            setNote('');
          }}
          confirmButtonProps={{
            disabled: !note,
          }}
          confirmationSettings={{
            variant: 'modal',
            modal: {
              heading: t('attention'),
              content: (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='warning'>{t('blockingAlert', { accountType: t(type) })}</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      autoFocus
                      label={t('reason')}
                      onChange={handleNote}
                      value={note}
                      multiline
                      rows={5}
                      rowsMax={20}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      required
                    />
                  </Grid>
                </Grid>
              ),
            },
          }}
        >
          <BlockButton />
        </Confirmation>
      )}
      {status === 'BLOCKED' && hasUnblockPermission && (
        <Confirmation
          onConfirm={() => {
            unblockAccount(record.id, type, note);
            setNote('');
          }}
          confirmButtonProps={{
            disabled: !note,
          }}
          confirmationSettings={{
            variant: 'modal',
            modal: {
              heading: t('attention'),
              content: (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='warning'>
                      {t('unblockingAlert', { accountType: t(type) })}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      autoFocus
                      label={t('reason')}
                      onChange={handleNote}
                      value={note}
                      multiline
                      rows={5}
                      rowsMax={20}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      required
                    />
                  </Grid>
                </Grid>
              ),
            },
          }}
        >
          <UnblockButton />
        </Confirmation>
      )}
      {status !== 'CLOSED' && hasClosePermission && (
        <Confirmation
          onConfirm={() => {
            closeAccount(record.id, type, note);
            setNote('');
          }}
          confirmButtonProps={{
            disabled: !note,
          }}
          confirmationSettings={{
            variant: 'modal',
            modal: {
              heading: t('attention'),
              content: (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='warning'>{t('closingAlert', { accountType: t(type) })}</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      autoFocus
                      label={t('reason')}
                      onChange={handleNote}
                      value={note}
                      multiline
                      rows={5}
                      rowsMax={20}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      required
                    />
                  </Grid>
                </Grid>
              ),
            },
          }}
        >
          <CloseButton />
        </Confirmation>
      )}
    </>
  );
};

export default AccountsActions;
